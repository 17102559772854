












import Vue from "vue";
import Actions from "./components/Actions.vue";
import Notes from "./components/Notes.vue";
import Todos from "./components/Todos.vue";

export default Vue.extend({
  name: "App",

  components: {
    Notes,
    Actions,
    Todos,
  },
});
