
























































import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  data() {
    return {
      clearDialog: false,
    };
  },
  computed: mapGetters(["historyAvailability"]),
  methods: mapMutations([
    "undo",
    "redo",
    "loadFromFile",
    "saveToFile",
    "clear",
  ]),
});
