






















































import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  computed: mapGetters(["selectedNote"]),
  methods: mapMutations([
    "newTodo",
    "updateNoteName",
    "updateTodoName",
    "updateTodoStatus",
    "deleteTodo",
  ]),
});
