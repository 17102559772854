

































import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  computed: mapGetters(["allNotes", "selectedNoteIndex"]),
  methods: mapMutations(["newNote", "setSelectedNote", "deleteNote"]),
});
